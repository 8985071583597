import React from "react";
import FavoriteItem from "./FavoriteItem";
import noFavorite from "src/assets/icons/empty-cart.png";
import { useTranslation } from "react-i18next";

const FavoriteItemsList = ({ products, loading }) => {
  const { t } = useTranslation("dashboard");
  if (loading) {
    return (
      <div className="commandes-list flex-1 dark:text-neutral-50 rounded-3xl p-4 md:p-6">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold mb-4">Mes favoris</h3>
        </div>
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <div className="commandes-list flex-1 h-60 dark:text-neutral-50 rounded-3xl p-4 md:p-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold mb-4">
          {t("savedItemsList.title")}
        </h3>
        <a
          href="/market-place/fav"
          className="text-xl font-normal cursor-pointer hover:underline mb-4"
        >
          {t("savedItemsList.viewAll")}
        </a>
      </div>
      {products?.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full">
          <img
            src={noFavorite}
            alt="wallet"
            className="w-36 mb-2 filter dark:neutralscale transform transition-transform duration-300 group-hover:scale-105"
          />
          <p> {t("savedItemsList.noSavedItems")}</p>
        </div>
      ) : (
        <ul className="list-inside max-h-80 overflow-y-auto scrollbar-hide  scrollbar-thumb-rounded scrollbar-thumb-neutral-400 dark:scrollbar-thumb-neutral-600">
          {products?.slice(0, 3).map((product, index) => (
            <li key={index}>
              <FavoriteItem product={product} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FavoriteItemsList;