import React from "react";
import { useNavigate } from "react-router";

const FavoriteItem = ({ product }) => {
  const { photo, name, productInfo, transactionInfo, type, _id } = product;

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/product-details/${_id}`);
  };
  return (
    <div className="flex items-center justify-between my-4 p-4 bg-white/30 dark:bg-neutral-600 bg-opacity-50 backdrop-filter backdrop-blur-xl rounded-2xl  w-full max-w-4xl mx-auto">
      <div className="flex items-center">
        <img
          src={photo.fileLink_Atatchement}
          alt={name}
          className="w-14 h-14 object-cover rounded-full mr-4"
        />
        <div>
          <h2 className="text-lg font-semibold">{name}</h2>
          {type === "produit" ? (
            <p className="text-neutral-600 dark:text-neutral-400">
              {productInfo?.price} TND
            </p>
          ) : (
            <p className="text-neutral-600 dark:text-neutral-400">
              {transactionInfo?.beneficiary}
            </p>
          )}
        </div>
      </div>
      <button
        onClick={handleClick}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Consulter
      </button>
    </div>
  );
};

export default FavoriteItem;
