import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Slider = ({ solde, setAmountGolobal }) => {
  const [value, setValue] = useState(solde); // Default value of the slider
  const { t } = useTranslation("productDetails"); // Initialize useTranslation hook

  const handleSliderChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setAmountGolobal(value);
  }, [value]);

  return (
    <div className="flex flex-col items-center justify-center  w-full ">
      <style>{`
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #38BDF8; /* Thumb color */
          cursor: pointer;
        }

        input[type="range"]::-moz-range-thumb {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #38BDF8; /* Thumb color */
          cursor: pointer;
        }
      `}</style>
      <div className="w-full  space-y-2 max-w-lg">
        <div className="">
          <span className=" font-semibold  dark:text-white">
          {t("budget")}
          </span>
        </div>
        {solde > 0 ? (
          <>
            <input
              type="range"
              min="0"
              max={solde}
              value={value}
              style={{
                // Thumb styling for Chrome/Safari
                WebkitSliderThumb: {
                  backgroundColor: "#38BDF820", // Thumb color
                },
                // Thumb styling for Firefox
                MozSliderThumb: {
                  backgroundColor: "#38BDF820", // Thumb color
                },
              }}
              onChange={handleSliderChange}
              className="w-full h-2   bg-white/60 backdrop-blur-lg rounded-lg appearance-none cursor-pointer   hover:bg-white hover:backdrop-blur-none"
            />
            <div className=" ">
              <span className=" font-semibold  dark:text-white">{t('tnd')} {value}</span>
            </div>
          </>
        ) : (
          <div className="text-red-500">Solde insuffisant</div>
        )}
      </div>
    </div>
  );
};

export default Slider;
