// src/utils/axiosClient.js
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
const isLoginPage = window.location.pathname === "/login"; // Check if the current page is the login page

// Request Interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach the token to the headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const messageFunction = window.messageFunction; // Get the global message function
   
    if (error.response?.status === 401) {
      // Handle 401 Unauthorized error (token expired or invalid)
      localStorage.removeItem("authToken"); // Remove the expired token
      if (!isLoginPage) {
        window.location.href = "/login"; // Redirect to the login page only if not already there
      }
      messageFunction("Login failed", "error");
    } if (error.response?.status === 500) {
      messageFunction("An unexpected error occurred. Please try again later.", "error");
    } else if (error.response?.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage = error.response.data?.message; // Assuming the message is in error.response.data.message
      if (errorMessage) {
        messageFunction(errorMessage, "error"); // Display error message
      } else {
        messageFunction("Bad Request", "error"); // Default message if no specific message is provided
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
