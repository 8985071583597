import home_en from "./locales/en/home.json";
import home_fr from "./locales/fr/home.json";
import home_ar from "./locales/ar/home.json"; // Add Arabic import
import login_en from "./locales/en/login.json";
import login_fr from "./locales/fr/login.json";
import login_ar from "./locales/ar/login.json"; // Add Arabic import
import dahboard_fr from "./locales/fr/dashboard.json";
import dahboard_en from "./locales/en/dashboard.json";
import dahboard_ar from "./locales/ar/dashboard.json"; // Add Arabic import
import userAvatarMenu_en from "./locales/en/userAvatarMenu.json";
import userAvatarMenu_fr from "./locales/fr/userAvatarMenu.json";
import userAvatarMenu_ar from "./locales/ar/userAvatarMenu.json"; // Add Arabic import
import productDetails_en from "./locales/en/productDetails.json";
import productDetails_fr from "./locales/fr/productDetails.json";
import productDetails_ar from "./locales/ar/productDetails.json"; // Add Arabic import
import command_en from "./locales/en/command.json";
import command_fr from "./locales/fr/command.json";
import command_ar from "./locales/ar/command.json"; // Add Arabic import
import marketPlace_en from "./locales/en/marketPlace.json";
import marketPlace_fr from "./locales/fr/marketPlace.json";
import marketPlace_ar from "./locales/ar/marketPlace.json"; // Add Arabic import
import history_en from "./locales/en/TransactionsHistory.json";
import history_fr from "./locales/fr/TransactionsHistory.json";
import history_ar from "./locales/ar/TransactionsHistory.json"; // Add Arabic import

const transltionRSC = {
  en: {
    home: home_en,
    login: login_en,
    dashboard: dahboard_en,
    userAvatarMenu: userAvatarMenu_en,
    marketPlace: marketPlace_en,
    productDetails: productDetails_en,
    command: command_en,
    history: history_en,
  },
  fr: {
    home: home_fr,
    login: login_fr,
    dashboard: dahboard_fr,
    userAvatarMenu: userAvatarMenu_fr,
    productDetails: productDetails_fr,
    command: command_fr,
    marketPlace: marketPlace_fr,
    history: history_fr,
  },
  ar: {  // Add Arabic translations
    home: home_ar,
    login: login_ar,
    dashboard: dahboard_ar,
    userAvatarMenu: userAvatarMenu_ar,
    productDetails: productDetails_ar,
    command: command_ar,
    marketPlace: marketPlace_ar,
    history: history_ar,
  },
};

export default transltionRSC;
