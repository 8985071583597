// src/context/AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axiosClient from "../services/axiosclient"; // Import your Axios client
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Manage user state
  const [loading, setLoading] = useState(false); // To handle loading state during app initialization
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedUser = localStorage.getItem("user");

    if (token && storedUser) {
      setUser(JSON.parse(storedUser)); // Set the user from localStorage
      setLoading(false);
    } else {
      setLoading(false); // No token found, stop loading
    }
  }, []);

  const updateUserSolde = async (ribStatus = null, newSolde) => {
    try {
      const updatedUser = { ...user };
      console.log("newSolde", newSolde);

      if (ribStatus != null) {
        updatedUser.ribStatus = ribStatus;
      }
      if (updatedUser.agenceInfo) {
        updatedUser.agenceInfo.solde = newSolde;
      } else if (updatedUser.businessFinderInfo) {
        updatedUser.businessFinderInfo.solde = newSolde;
      } else {
        throw new Error("No solde field found to update");
      }

      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));
    } catch (error) {
      console.error("Failed to update solde:", error);
      throw new Error("Failed to update solde");
    }
  };

  const registerUser = async (data) => {
    try {
      const response = await axiosClient.post("/auth/register", data); // Register endpoint
      const { user, token } = response.data;

      localStorage.setItem("authToken", token); // Store token
      localStorage.setItem("user", JSON.stringify(user)); // Store user in localStorage
      setUser(user); // Set the user state
      navigate("/dashboard"); // Navigate to dashboard on success
    } catch (error) {
      throw new Error("Registration failed");
    }
  };

  const loginUser = async (data) => {
    try {
      const response = await axiosClient.post(
        "/auth/login?isMarketPlace=true",
        data
      ); // Login endpoint
      const { user, token } = response.data;
      console.log(user);
      localStorage.setItem("authToken", token); // Store token
      localStorage.setItem("user", JSON.stringify(user)); // Store user in localStorage
      setUser(user); // Set the user state
      navigate("/dashboard"); // Redirect to the dashboard
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken"); // Clear the token from storage
    localStorage.removeItem("user"); // Clear the user from storage
    setUser(null); // Clear the user state
    navigate("/login"); // Redirect to login page
  };

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a proper loading spinner
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        registerUser,
        loginUser,
        loading,
        logout,
        updateUserSolde,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
