// src/components/MessageDisplay.js
import React from "react";
import { motion } from "framer-motion";
import { useMessage } from "src/context/MessageContext";

const messageVariants = {
  success: {
    borderLeft: "5px solid #28a745", // Green border for success
    opacity: 1,
    scale: 1, // Default scale
  },
  error: {
    borderLeft: "5px solid #dc3545", // Red border for error
    opacity: 1,
    scale: 1, // Default scale
  },
  warning: {
    borderLeft: "5px solid #ffc107", // Yellow border for warning
    opacity: 1,
    scale: 1, // Default scale
  },
  exit: {
    opacity: 0,
    scale: 0.9, // Scale down on exit for the popup effect
    transition: { duration: 0.5 },
  },
};

const MessageDisplay = () => {
  const { messages } = useMessage();

  const getIcon = (type) => {
    switch (type) {
      case "success":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        );
      case "error":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed top-4 ltr:right-4 rtl:left-4 z-50" aria-live="polite">
      {messages.map((msg) => (
        <motion.div
          key={msg.id} // Use the unique ID for the key
          className="p-4 mb-2 border-l-2 flex backdrop-filter backdrop-blur-2xl rounded-2xl bg-white/70 shadow-lg"
          initial={{ opacity: 0, scale: 0.9 }} // Initial state for entrance with scale
          animate={{ opacity: 1, scale: 1 }} // Animate to full opacity and scale
          exit={messageVariants.exit} // Define exit animation
          transition={{ duration: 0.5 }} // Add a duration for the entrance and exit
          variants={messageVariants[msg.type]} // Apply message-specific styles
        >
          <div className="mr-2">{getIcon(msg.type)}</div>
          <span>{msg.message}</span>
        </motion.div>
      ))}
    </div>
  );
};

export default MessageDisplay;
