import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDrawer } from "src/context/DrawerContext";
import { useTheme } from "src/ThemeContext";
import CreditCard from "../common/CreditCard";
import { useAuth } from "src/context/AuthProvider";
import orangeCurved from "../../assets/images/orangeCurved.png";
import rainbow from "../../assets/images/rainbow.png";
import balls from "../../assets/images/balls.png";
const BlurredDrawer = () => {
  const { isOpen, closeDrawer, title, drawerContent } = useDrawer();

  return (
    <AnimatePresence className=" bg-green-400">
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0  bg-neutral-400/20 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeDrawer}
          />

         {drawerContent}
        </>
      )}
    </AnimatePresence>
  );
};

export default BlurredDrawer;
