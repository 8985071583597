import React, { useState } from "react";
import { motion } from "framer-motion";
import balls from "../../assets/images/rainbow2.png";

const CreditCard = () => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const lightBackground = `
    radial-gradient(at 62% 18%, hsla(202,84%,80%,0.3) 0px, transparent 50%),
    radial-gradient(at 10% 80%, hsla(202,84%,80%,0.4) 0px, transparent 50%),
    radial-gradient(at 5% 25%, hsla(202,84%,80%,0.7) 0px, transparent 50%),
    radial-gradient(at 4% 14%, hsla(202,84%,80%,0.02) 0px, transparent 50%),
    radial-gradient(at 100% 100%, hsla(0, 84.2%, 60.2%,0) 0px, transparent 65%)
  `;
  return (
    <motion.div
      style={{
        backgroundImage: `url(${balls})`,
        backgroundPosition: "-100% 260%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "80% 80%",
      }}
      className="relative   h-2/6 bg-white dark:bg-neutral-800 rounded-3xl shadow-lg p-4 flex flex-col justify-between"
      initial={{ opacity: 0, x: "100%" }} // Start off-screen
      animate={{ opacity: 1, x: 0 }} // Animate to visible position
      exit={{ opacity: 0, x: "100%" }} // Exit off-screen
      transition={{ type: "spring", stiffness: 300, damping: 30 }} // Smooth animation
    >
      <div className="flex absolute top-3 ltr:right-3 rtl:left-3 justify-between -space-x-5 items-center">
        <div className="w-12 aspect-square rounded-full bg-red-500"></div>
        <div className="w-12 aspect-square rounded-full bg-yellow-500/80"></div>
      </div>

      <div className="flex flex-col space-y-2">
        <label className="text-sm placeholder-neutral-900 text-neutral-900 dark:placeholder-neutral-200 dark:text-neutral-200">
          Credit Card
        </label>
        <input
          type="text"
          className="text-2xl font-semibold placeholder-neutral-900 bg-red-400 dark:placeholder-neutral-200 dark:text-neutral-200 text-neutral-900 bg-transparent border-none focus:outline-none focus:ring-0"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
          placeholder="John Doe"
        />
      </div>

      <div className="flex justify-between">
        <input
          type="text"
          className="text-lg placeholder-neutral-900 text-neutral-900 dark:placeholder-neutral-200 dark:text-neutral-200 w-3/6 bg-white dark:bg-neutral-800 rounded-lg px-2 bg-transparent border-none focus:outline-none focus:ring-0"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          placeholder="**** **** **** 1234"
          maxLength="19"
        />
        {/* <input
          type="text"
          className="text-lg w-1/5 placeholder-neutral-900 text-neutral-900 dark:placeholder-neutral-200 dark:text-neutral-200 bg-transparent border-none focus:outline-none focus:ring-0"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          placeholder="12/24"
          maxLength="5"
        /> */}
      </div>
    </motion.div>
  );
};

export default CreditCard;
