import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [title, setTitle] = useState('');

  const openDrawer = ({ content, title = '' }) => {
    setDrawerContent(content);
    setIsOpen(true);
    setTitle(title); // Ensure the title is set here
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setDrawerContent(null);
    setTitle(''); // Reset the title when closing
  };

  return (
    <DrawerContext.Provider value={{ isOpen, openDrawer, closeDrawer, title, drawerContent }}>
      {children}
    </DrawerContext.Provider>
  );
};
