// src/routes/AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Route, Routes,Navigate  } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import ProductDetails from "../pages/dashboard/ProductDetails";
import LoadingPage from "src/components/common/InfoComponents/LoadingPage";
import { AnimatePresence } from "framer-motion";

// Lazy load pages
const Home = lazy(() => import("../pages/public/Home"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const MarketPlace = lazy(() => import("../pages/dashboard/MarketPlace"));
const Login = lazy(() => import("../pages/auth/Login"));
const NotFound = lazy(() => import("../pages/notFound/NotFound"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <AnimatePresence mode="wait">
        <Routes>
          {/* <Route path="/" element={<Home />} />
           */}
          <Route path="/" element={<Navigate to="/market-place" replace />} />

          <Route element={<MainLayout />}>
            {/* <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} /> */}
            <Route
              path="/market-place/:fav?"
              element={
                // <PrivateRoute>
                <MarketPlace />
                // </PrivateRoute>
              }
            />
            <Route
              path="/product-details/:id"
              element={
                // <PrivateRoute>
                <ProductDetails />
                // </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                // <PrivateRoute>
                <Dashboard />
                // </PrivateRoute>
              }
            />{" "}
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
};

export default AppRoutes;
