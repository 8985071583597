// src/context/MessageContext.js
import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (message, type) => {
    const id = Date.now(); // Unique ID for each message
    setMessages((prevMessages) => [...prevMessages, { id, message, type }]);

    // Remove message after 5 seconds
    setTimeout(() => {
      setMessages((prevMessages) => prevMessages.filter((m) => m.id !== id));
    }, 5000);
  };

  return (
    <MessageContext.Provider value={{ messages, addMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
