// src/App.js
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "src/routes/AppRoutes";
import { AuthProvider } from "src/context/AuthProvider";
import Loader from "src/components/common/InfoComponents/Loader"; // Fallback loader component
import { DrawerProvider } from "./context/DrawerContext";
import { MessageProvider } from "./context/MessageContext";
import MessageDisplay from "./components/common/MessageDisplay";

const App = () => {
  return (
    <Router>
      <MessageProvider>
        <AuthProvider>
          <DrawerProvider>
            {/* <ThemeProvider> */}
            {/* <MainLayout> */}
            <AppRoutes />
            <MessageDisplay /> {/* Add the MessageDisplay component here */}

            {/* </MainLayout> */}
            {/* </ThemeProvider> */}
          </DrawerProvider>
        </AuthProvider>
      </MessageProvider>
    </Router>
  );
};

export default App;
