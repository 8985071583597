import React, { useRef, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import { motion } from "framer-motion";
import { useTheme } from "src/ThemeContext";
import { useAuth } from "src/context/AuthProvider";
import BlurredDrawer from "./BlurredDrawer"; // Import your BlurredDrawer component
import { useDrawer } from "src/context/DrawerContext";
import { useMessage } from "src/context/MessageContext";

const MainLayout = () => {
  const [scrolled, setScrolled] = useState(false);
  const mainRef = useRef(null);
  const { loading } = useAuth();
  const location = useLocation();
  const { theme } = useTheme();
  const { isOpen, closeDrawer, drawerContent } = useDrawer();
  const { addMessage } = useMessage();

  // Check if current page is "product-details"
  const isProductDetails = location.pathname.includes("product-details");
  useEffect(() => {
    // Set the message function for axios to call
    const setAxiosMessageFunction = () => {
      // Make sure to clean it up when this component unmounts
      return () => {
        window.messageFunction = null;
      };
    };
  
    // Set the function on the window object
    window.messageFunction = addMessage;
  
    return setAxiosMessageFunction();
  }, [addMessage]);
  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current) {
        if (mainRef.current.scrollTop > 80) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      }
    };

    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainElement) {
        mainElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const lightBackground = `
   radial-gradient(at 62% 18%, hsla(202,84%,80%,0.2) 0px, transparent 50%),
    radial-gradient(at 10% 80%, hsla(202,84%,80%,0.4) 0px, transparent 50%),
    radial-gradient(at 40% 45%, hsla(202,84%,80%,0.6) 0px, transparent 50%),
    radial-gradient(at 4% 14%, hsla(202,84%,80%,0.3) 0px, transparent 50%),
    radial-gradient(at 100% 100%, hsla(20.5, 90.2%, 48.2%,0.2) 0px, transparent 65%)`;

  return (
    <motion.div

      className={`scrollbar-hide  transition-all duration-300 bg-white dark:bg-neutral-900 flex flex-col h-screen bg-cover bg-center backdrop-blur-3xl ${
        theme === "light" ? "animate-Background" : ""
      }`}
        style={{
          // direction:'rtl',
        backgroundImage: theme === "dark" ? "" : lightBackground,
        backgroundSize: "170% 170%",
      }}
    >
      <div className="flex flex-col h-screen relative">
        <Header scrolled={scrolled || isProductDetails} />

        <div
          className={`flex flex-1 min-h-max overflow-hidden transition-transform duration-300 ${
            isOpen ? " scale-75   ltr:-translate-x-40 rtl:translate-x-40" : "scale-100 translate-x-0"
          }`}
        >
          <main
            ref={mainRef}
            className={`flex-1  h-screen overflow-y-auto scrollbar-hide scroll-smooth justify-center items-center transition-all duration-300 ${
              !(scrolled || isProductDetails)
                ? "py-10 px-6 sm:py-16 sm:px-12 md:py-20 md:px-20"
                : "py-10 px-6 sm:py-16 sm:px-16 md:py-20 md:px-20"
            }`}
          >
            <div style={{ height: "60px", width: "100%" }}></div>
            <Outlet />
          </main>
        </div>
      </div>

      <BlurredDrawer open={isOpen} onClose={closeDrawer} >
        {drawerContent}
      </BlurredDrawer>
    </motion.div>
  );
};

export default MainLayout;
