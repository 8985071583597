import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const QuantityCounter = ({ setQuantityGlobal }) => {
  const { t } = useTranslation("productDetails"); // Initialize useTranslation hook
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    setQuantityGlobal(quantity);
  }, [quantity]);
  return (
    <div className=" space-y-2 w-full ">
      <h3 className=" font-semibold dark:text-white "> {t("quantity")}</h3>
      <div className="flex   border dark:border-white border-black backdrop-blur-xl    p-1 w-1/2 rounded-xl  justify-between gap-x-10 items-center">
        <button onClick={() => quantity > 1 && setQuantity(quantity - 1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 dark:text-white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
          </svg>
        </button>
        <p className="font-semibold  dark:text-white">{quantity}</p>

        <button onClick={() => quantity >= 1 && setQuantity(quantity + 1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 dark:text-white "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default QuantityCounter;
