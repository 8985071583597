import React from "react";
import LoadingPage from "./InfoComponents/LoadingPage";
import ProductCard from "./ProductCard";
import { motion } from 'framer-motion';

const ProductGrid = React.memo(({ items, loading }) => {
  return loading ? (
    <LoadingPage />
  ) : (
    <motion.div
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full justify-items-center"
      initial="hidden"
      animate="visible"
    >
      {items?.map((item, index) => (
        <motion.div
          key={item._id} 
          className="transform transition-opacity duration-500 ease-in-out"
          initial={{ opacity: 0, scale: 0.70 }} 
          animate={{ opacity: 1, scale: 1 }} 
          transition={{
            delay: index * 0.15, 
            duration: 0.5, 
          }}
        >
          <ProductCard item={item} />
        </motion.div>
      ))}
    </motion.div>
  );
});

export default ProductGrid;
