import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import HeartOutline from "../../components/common/HeartOutline";
import HeartFilled from "../../components/common/HeartFilled";
import { useMutation } from "@tanstack/react-query";
import axiosClient from "src/services/axiosclient";

const ProductCard = ({ item }) => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(item.isSaved || false);

  const handleClick = () => {
    navigate(`/product-details/${item?._id}`);
  };

  const mutation = useMutation({
    mutationFn: async () => {
      await axiosClient.patch(
        `/marketPlace/user/updateSavedArticles/${item?._id}`
      );
    },
    onSuccess: () => {
      setIsSaved((prev) => !prev);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleSaveProduct = (e) => {
    e.stopPropagation();
    mutation.mutate();
  };

  return (
    <motion.div
      onClick={handleClick}
      className="flex flex-col w-full sm:w-auto max-w-full rounded-3xl overflow-hidden backdrop-blur-3xl bg-white/40 dark:bg-neutral-600/30 gap-2 p-4"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <motion.div className="relative w-full aspect-square bg-white/30 dark:bg-neutral-600/30 rounded-xl">
        {item.category === "Nouveauté" && (
          <div className="absolute top-2 left-2 px-2 py-1 bg-green-400 text-white  rounded-lg">
            NEW
          </div>
        )}
        <button
          className="bg-white/95 text-neutral-800 p-2 top-2 right-2 rounded-lg absolute transition-transform duration-300 z-50"
          onClick={handleSaveProduct}
          aria-label={isSaved ? "Unsave Product" : "Save Product"}
          disabled={mutation.isPending} 
        >
          {mutation.isPending ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="animate-spin h-5 w-5 "
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            </>
          ) : isSaved ? (
            <HeartFilled />
          ) : (
            <HeartOutline />
          )}
        </button>
        <motion.img
          layoutId={`card-image-${item?._id}`}
          className="w-full aspect-square object-contain rounded-xl p-6"
          src={item?.photo?.fileLink_Atatchement}
          alt={item?.name}
          loading="lazy"
        />
      </motion.div>

      <div className="flex flex-col gap-2 p-2">
        <div className="pointer-events-auto uppercase font-normal tracking-wide text-black dark:text-white truncate whitespace-nowrap overflow-hidden">
          {item?.name || "Carte cadeau"}
        </div>
        <span className="block mt-1 text-lg leading-tight font-medium text-black dark:text-white">
          {`${item?.productInfo?.price || 0} TND  `}
        </span>
      </div>
    </motion.div>
  );
};

export default ProductCard;
