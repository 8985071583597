import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const FilterOptions = ({ onFilterChange }) => {
  const { t } = useTranslation("marketPlace");
  const filters = [
    { key: "All", label: "" },
    { key: "Favorites", label: "Favoris" },
    { key: "New", label: "Nouveauté" },
    { key: "Hobbies", label: "Loisirs" },
    { key: "Associations", label: "Associations" },
    { key: "Transfers", label: "Virements" },
    { key: "Training", label: "Formations" },
  ];

  const { fav } = useParams();
  // Initialize activeFilters to include the "All" filter
  const [activeFilters, setActiveFilters] = useState(
    fav === "fav" ? ["Favoris"] : [""]
  );
  const containerRef = useRef(null);

  const handleFilterClick = (filterKey) => {
    setActiveFilters((prev) => {
      // Reset to only include "All" when "All" is clicked
      if (filterKey === "") {
        return [filterKey];
      }
  

      // Remove "All" when any other filter is clicked
      if (prev.includes("") && filterKey !== "") {
        return [filterKey];
      }
  
      // Toggle the selected filter (add/remove it from activeFilters)
      if (prev.includes(filterKey)) {
        const updatedFilters = prev.filter((f) => f !== filterKey);
        // If all filters are removed, default back to "All"
        return updatedFilters.length === 0 ? [""] : updatedFilters;
      } else {
        // Add the filter to the activeFilters list
        return [...prev, filterKey];
      }
    });
  };

  useEffect(() => {
    onFilterChange(activeFilters);
    if (activeFilters.length === 0) setActiveFilters([""]);
  }, [activeFilters]);

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-between py-4 gap-4 w-full">
      <div
        className="flex flex-wrap space-x-4 items-start justify-start w-full md:w-auto"
        ref={containerRef}
      >
        {filters.map((filter) => (
          <p
            key={filter.label}
            onClick={() => handleFilterClick(filter.label)}
            className={`text-sm sm:text-base md:text-lg font-medium inline-block px-3 py-1 rounded-full cursor-pointer transition-transform duration-500 ease-in-out ${
              activeFilters.includes(filter.label)
                ? "text-white  bg-black dark:bg-white dark:text-neutral-950"
                : "text-neutral-950 dark:text-neutral-50 hover:scale-110 bg-transparent"
            }`}
          >
            {t("filters." + filter.key)}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FilterOptions;
