import React from "react";
import "tailwindcss/tailwind.css";
import logo from "src/assets/icons/Logo.png";

const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen  ">
      <div className="animate-pulse-fast">
        <img src={logo} alt="App Logo" className="w-24 " />
      </div>
    </div>
  );
};

export default LoadingPage;
