// src/pages/dashboard/Dashboard.js
import React from "react";
import { useAuth } from "src/context/AuthProvider";
import ProductCard from "src/components/common/ProductCard";
import FilterOptions from "src/components/common/FilterOptions";
import ProductGrid from "src/components/common/ProductsGrid";
import ProductDetailsCard from "src/components/common/ProductDetailsCard";

const ProductDetails = () => {
  const { user, logout } = useAuth();
  const items = Array.from({ length: 14 }, (_, index) => `Item ${index + 1}`);

  return (
    <div className="mt-3 space-y-4  ">
      {/* <FilterOptions /> */}
      {/* <ProductGrid items={items} /> */}
     <ProductDetailsCard></ProductDetailsCard>
    </div>
  );
};

export default ProductDetails;
